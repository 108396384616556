import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import { onError } from "../lib/errorLib";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../lib/awsLib";
import AWS from 'aws-sdk';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

export default function Listings() {
  const file = useRef(null);
  const { sectorName, companyId } = useParams();
  const history = useHistory();
  const [listing, setListing] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [brands, setBrands] = useState("");
  const [carbon, setCarbon] = useState({});
  const [financials, setFinancials] = useState({});
  const [dataSource, setDataSource] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);


  useEffect(() => {

async function loadListing() {

    const credentials = await Auth.currentCredentials();

    var params = {
      TableName : 'dev-notes-Listings',
      Key: {
        sectorName: sectorName,
        companyId: companyId,
      }
    };

    var documentClient = new AWS.DynamoDB.DocumentClient({region: 'us-east-1', credentials: Auth.essentialCredentials(credentials)});

    //documentClient.get(params, function(err, data) {
    //  if (err) console.log(err);
    //  else return data.Item;
    //});

    const response = await documentClient.get(params).promise();
        console.log(response.Item);
    return response.Item;

}


//    function loadListing() {
//      return API.get("listings", `/listings/${sectorName}/${companyId}`);
//    }

    async function onLoad() {
      try {
        const listing = await loadListing();
	console.log(listing);
        const { companyName, brands, carbon, financials, dataSource} = listing;


        setCompanyName(companyName);
        setBrands(brands);
	setCarbon(carbon);
        setFinancials(financials);
        setDataSource(dataSource);
        setListing(listing);

      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [sectorName, companyId]);



        const handleChange = e => {
            const { name, value } = e.target;
            setCarbon(prevState => ({
                ...prevState,
                [name]: value
            }));
        };


        const handleFinancialsChange = e => {
            const { name, value } = e.target;
            setFinancials(prevState => ({
                ...prevState,
                [name]: value
            }));
        };


function validateForm() {
  return companyName.length > 0;
}

function formatFilename(str) {
  return str.replace(/^\w+-/, "");
}

function handleFileChange(event) {
  file.current = event.target.files[0];
}


async function saveListing(data) {
    const credentials = await Auth.currentCredentials();


  const params = {
    TableName: 'dev-notes-Listings',
    Item: {
        sectorName: sectorName,
        companyId: companyId,
      companyName: data.companyName,
        carbon: data.carbon || null,
        brands: data.brands || null,
        financials: data.financials || null,
        dataSource: data.dataSource || null,
      createdAt: Date.now(), // Current Unix timestamp
    },
  };

var documentClient = new AWS.DynamoDB.DocumentClient({region: 'us-east-1', credentials: Auth.essentialCredentials(credentials)});

documentClient.put(params, function(err, data) {
  if (err) console.log(err);
  else console.log(data);
});

}




//function saveListing(listing) {
//  return API.put("listings", `/listings/${sectorName}/${companyId}`, {
//    body: listing
//  });
//}

async function handleSubmit(event) {
  let attachment;

  event.preventDefault();


  setIsLoading(true);

  try {
    if (file.current) {
      attachment = await s3Upload(file.current);
    }

    await saveListing({
      companyName,
      brands,
      carbon,
	financials,
      dataSource,
      attachment: attachment || listing.attachment
    });
    history.push(`/${sectorName}`);
  } catch (e) {
    onError(e);
    setIsLoading(false);
  }
}

async function deleteListing() {

    const credentials = await Auth.currentCredentials();

    var params = {
      TableName : 'dev-notes-Listings',
      Key: {
        sectorName: sectorName,
        companyId: companyId,
      }
    };

    var documentClient = new AWS.DynamoDB.DocumentClient({region: 'us-east-1', credentials: Auth.essentialCredentials(credentials)});

    documentClient.delete(params, function(err, data) {
      if (err) console.log(err);
      else console.log(data);
    });


}


//function deleteListing() {
//  return API.del("listings", `/listings/${sectorName}/${companyId}`);
//}

async function handleDelete(event) {
  event.preventDefault();

  const confirmed = window.confirm(
    "Are you sure you want to delete this listing?"
  );

  if (!confirmed) {
    return;
  }

  setIsDeleting(true);

  try {
    await deleteListing();
    history.push(`/${sectorName}`);
  } catch (e) {
    onError(e);
    setIsDeleting(false);
  }
}

return (
  <div className="col-md-8 offset-2">
    {listing && (<div className="card p-2">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="companyName" className="m-2">
        <Form.Label>Company Name</Form.Label>
          <Form.Control
            value={companyName}
            type="text"
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="brands" className="m-2">
          <Form.Label>Brands</Form.Label>
          <Form.Control
            value={brands}
            type="text"
            onChange={(e) => setBrands(e.target.value)}
          />
        </Form.Group>


	<div className="card-body m-2">
	<p>Carbon Emissions Data</p>
        <table className="table">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Baseline</th>
            <th scope="col">Year -2</th>
            <th scope="col">Year -1</th>
            <th scope="col">Year 0</th>
            <th scope="col">Target</th>
          </tr>
        </thead>

        <tr>
        <td>Years</td>
        <td>
        <Form.Group controlId="baselineYear">
          <Form.Control
            value={carbon.baselineYear}
            name="baselineYear"
            type="text"
            onChange={handleChange}
          />
        </Form.Group>
        </td>
        <td>
        <Form.Group controlId="year1">
          <Form.Control
            value={carbon.year1}
            type="text"
            name="year1"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="year2">
          <Form.Control
            value={carbon.year2}
            type="text"
            name="year2"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="year3">
          <Form.Control
            value={carbon.year3}
            name="year3"
            type="text"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="targetYear">
          <Form.Control
            value={carbon.targetYear}
            name="targetYear"
            type="text"
            onChange={handleChange}
          />
        </Form.Group>
        </td>
        </tr>


        <tr>
        <td>Scope 1 - Location Based</td>
        <td>
        <Form.Group controlId="s1BaselineYearValue">
          <Form.Control
            value={carbon.s1BaselineYearValue}
            type="text"
            name="s1BaselineYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s1Year1Value">
          <Form.Control
            value={carbon.s1Year1Value}
            type="text"
            name="s1Year1Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s1Year2Value">
          <Form.Control
            value={carbon.s1Year2Value}
            type="text"
            name="s1Year2Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s1Year3Value">
          <Form.Control
            value={carbon.s1Year3Value}
            type="text"
            name="s1Year3Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s1TargetYearValue">
          <Form.Control
            value={carbon.s1TargetYearValue}
            type="text"
            name="s1TargetYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td>
        </tr>

        <tr>
        <td>Scope 2 - Location Based</td>
        <td>
        <Form.Group controlId="s2BaselineYearValue">
          <Form.Control
            value={carbon.s2BaselineYearValue}
            type="text"
            name="s2BaselineYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2Year1Value">
          <Form.Control
            value={carbon.s2Year1Value}
            type="text"
            name="s2Year1Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2Year2Value">
          <Form.Control
            value={carbon.s2Year2Value}
            type="text"
            name="s2Year2Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2Year3Value">
          <Form.Control
            value={carbon.s2Year3Value}
            type="text"
            name="s2Year3Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2TargetYearValue">
          <Form.Control
            value={carbon.s2TargetYearValue}
            type="text"
            name="s2TargetYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td>
        </tr>

        <tr>
        <td>Scope2 - Market Based</td>
        <td>
        <Form.Group controlId="s2MBaselineYearValue">
          <Form.Control
            value={carbon.s2MBaselineYearValue}
            type="text"
            name="s2MBaselineYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2MYear1Value">
          <Form.Control
            value={carbon.s2MYear1Value}
            type="text"
            name="s2MYear1Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2MYear2Value">
          <Form.Control
            value={carbon.s2MYear2Value}
            type="text"
            name="s2MYear2Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2MYear3Value">
          <Form.Control
            value={carbon.s2MYear3Value}
            type="text"
            name="s2MYear3Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2MTargetYearValue">
          <Form.Control
            value={carbon.s2MTargetYearValue}
            type="text"
            name="s2MTargetYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td>
        </tr>


        <tr>
        <td>Scope 3 - Location Based</td>
        <td>
        <Form.Group controlId="s3BaselineYearValue">
          <Form.Control
            value={carbon.s3BaselineYearValue}
            type="text"
            name="s3BaselineYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s3Year1Value">
          <Form.Control
            value={carbon.s3Year1Value}
            type="text"      
            name="s3Year1Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s3Year2Value">
          <Form.Control
            value={carbon.s3Year2Value}
            type="text"
            name="s3Year2Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s3Year3Value">
          <Form.Control
            value={carbon.s3Year3Value}
            type="text"
            name="s3Year3Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s3TargetYearValue">
          <Form.Control
            value={carbon.s3TargetYearValue}
            type="text"
            name="s3TargetYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td>
        </tr>


        <tr>
        <td>Revenues (Millions)</td>
        <td>
        <Form.Group controlId="revBaselineYearValue">
          <Form.Control
            value={financials && financials.revBaselineYearValue ? financials.revBaselineYearValue : ''}
            type="text"
            name="revBaselineYearValue"
            onChange={handleFinancialsChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="revYear1Value">
          <Form.Control
            value={financials && financials.revYear1Value ? financials.revYear1Value: ''}
            type="text"
            name="revYear1Value"
            onChange={handleFinancialsChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="revYear2Value">
          <Form.Control
            value={financials && financials.revYear2Value ? financials.revYear2Value : ''}
            type="text"
            name="revYear2Value"
            onChange={handleFinancialsChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="revYear3Value">
          <Form.Control
            value={financials && financials.revYear3Value ? financials.revYear3Value : ''}
            type="text"
            name="revYear3Value"
            onChange={handleFinancialsChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="revTargetYearValue">
          <Form.Control
            value={financials && financials.revTargetYearValue ? financials.revTargetYearValue : ''}
            type="text"
            name="revTargetYearValue"
            onChange={handleFinancialsChange}
          />
        </Form.Group>
        </td>
        </tr>

        </table>
	</div>

    <Form.Group controlId="reportingCurrency" className="m-2">
        <Form.Label>Select currency</Form.Label>
        <Form.Control
          as="select"
          name="reportingCurrency"
          value={financials && financials.reportingCurrency ? financials.reportingCurrency : ''}
          onChange={handleFinancialsChange}
        >
          <option value="No Currency Set">No Currency Set</option>
          <option value="GBP">GBP</option>
          <option value="SEK">SEK</option>
          <option value="EUR">EUR</option>
          <option value="USD">USD</option>
          <option value="JPY">JPY</option>
          <option value="CHF">CHF</option>
          <option value="CAD">CAD</option>
        </Form.Control>
      </Form.Group>


        <Form.Group controlId="notes" className="m-2">
          <Form.Label>Notes (all scopes)</Form.Label>
          <Form.Control as="textarea"
            value={carbon.notes}
	    name="notes"
            style={{ height: '150px', fontSize: 14 }}
            onChange={handleChange}
          />
        </Form.Group>


    <Form.Group controlId="scienceBasedTarget" className="m-2">
        <Form.Label>Select target</Form.Label>
        <Form.Control
          as="select"
          name="scienceBasedTarget"
          value={carbon.scienceBasedTarget}
          onChange={handleChange}
        >
          <option value="No Target Set">No Target Set</option>
          <option value="1.5 degrees">1.5 degrees</option>
          <option value="Well below 2 degrees">Well below 2 degrees</option>
          <option value="Between 1.5 and 2 degrees">Between 1.5 and 2 degrees</option>
          <option value="2 degrees">2 degrees</option>
          <option value="Committed">Committed</option>
        </Form.Control>
      </Form.Group>

        <Form.Group controlId="carbonNeutralBy" className="m-2">
          <Form.Label>Carbon neutral by</Form.Label>
          <Form.Control
            value={carbon.carbonNeutralBy}
            type="text"
            name="carbonNeutralBy"
            onChange={handleChange}
          />
        </Form.Group>

    <Form.Group controlId="carbonNeutralFor" className="m-2">
        <Form.Label>Select carbon netural scope</Form.Label>
        <Form.Control
          as="select"
          name="carbonNeutralFor"
          value={carbon.carbonNeutralFor}
          onChange={handleChange}
        >
          <option value=""></option>
          <option value="for own operations">for own operations</option>
          <option value="for entire value chain">for entire value chain</option>
        </Form.Control>
      </Form.Group>

        <Form.Group controlId="netZeroBy" className="m-2">
          <Form.Label>Net zero by</Form.Label>
          <Form.Control
            value={carbon.netZeroBy}
            type="text"
            name="netZeroBy"
            onChange={handleChange}
          />
        </Form.Group>

    <Form.Group controlId="netZeroFor" className="m-2">
        <Form.Label>Select net zero scope</Form.Label>
        <Form.Control
          as="select"
          name="netZeroFor"
          value={carbon.netZeroFor}
          onChange={handleChange}
        >
          <option value=""></option>
          <option value="for own operations">for own operations</option>
          <option value="for entire value chain">for entire value chain</option>
        </Form.Control>
      </Form.Group>

        <Form.Group controlId="dataSource" className="m-2">
          <Form.Label>Data Source</Form.Label>
          <Form.Control
            value={carbon.dataSource}
	    name="dataSource"
            type="text"
            onChange={handleChange}
          />
        </Form.Group>

    <Form.Group controlId="netZeroFor" className="m-2">
        <Form.Label>Set transparency level</Form.Label>
        <Form.Control
          as="select"
          name="transparencyLevel"
          value={carbon.transparencyLevel}
          onChange={handleChange}
        >
          <option value=""></option>
          <option value="high">high</option>
          <option value="medium">medium</option>
          <option value="low">low</option>
        </Form.Control>
      </Form.Group>

	<br/>
	<div className="m-2 card">
        <LoaderButton
          type="submit"
          className="btn btn-primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Save
        </LoaderButton><br/>
        <LoaderButton
          className="btn btn-danger"
          onClick={handleDelete}
          isLoading={isDeleting}
        >
          Delete
        </LoaderButton>
	</div>
      </Form></div>
    )}
  </div>
);


}
