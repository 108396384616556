import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import "./Home.css";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';

export default function Home() {
  const history = useHistory();

  const [sectors, setSectors] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  async function onLoad() {
    //if (!isAuthenticated) {
    //  return;
    //}

    try {
        ReactGA.pageview(window.location.pathname);

document.title = "Welcome to Greenhouse";
document.getElementsByTagName("META")[1].content="Greenhouse transparently discloses sustainability goals and achievements for consumer brands.";

      const sectors = await loadSectors();
      setSectors(sectors);
    } catch (e) {
      onError(e);
    }

    setIsLoading(false);
  }

  onLoad();
}, [isAuthenticated]);


function loadSectors() {
  const sectors = [
	{sectorId: 'luxury-fashion', sectorName: 'Luxury Fashion'},
	{sectorId: 'highstreet-fashion', sectorName: 'Highstreet Fashion'},
	{sectorId: 'online-fashion', sectorName: 'Online Fashion'},
	{sectorId: 'sportswear-and-athleisure', sectorName: 'Sportswear & Athleisure'},
        {sectorId: 'watches-and-jewellery', sectorName: 'Watches & Jewellery'},
  ];
  return sectors

}


function renderSectorsList(sectors) {
  return (
    <>
    <div id="sectors" className="col-md-12 offset-0 card-body p-5 mb-1 bg-light rounded-3">
    <h2 class="pb-2 border-bottom">Sectors</h2>
    <div className="d-flex flex-wrap justify-content-start mb-3">
    {sectors.map(({sectorId, sectorName}) => (

      <div className="card m-2 bg-light h-100 overflow-hidden border-success rounded-5 shadow-lg" key={sectorId}  style={{width: "18rem"}}>
        <div className="card-body">
          <h5 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">{sectorName}</h5>
          <a href={`/${sectorId}`} className="card-link">View listings</a>
        </div>
      </div>

      ))}
    </div>
    </div>
    </>
  );
}

  function renderLander() {
    return (
      <div className="1lander">


  <div class="container-fluid px-5 py-5">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
      <div class="col-md-6 p-10">
        <img src="gh-logo-light-background-strapline.png" class="d-block mx-lg-auto img-fluid" width="70%" height="auto" alt="Bootstrap Themes"  loading="lazy"></img>
      </div>
      <div class="col-md-6">
        <h1 class="display-5 fw-bold lh-1 mb-3">Welcome to Greenhouse</h1>
        <p class="lead">Greenhouse transparently discloses sustainability goals and achievements for consumer brands.</p>
        <p class="lead">Empowering stakeholders with this information results in more informed choices about the brands we want to buy from as well as the business that we choose to work for and associate with.</p>
        <p class="lead">Greenhouse is a free and open platform.</p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          <a type="button" class="btn btn-primary btn-lg px-4 me-md-2" href="#about">Learn more</a>
          <a type="button" class="btn btn-outline-secondary btn-lg px-4" href="mailto:chris@greenhouseesg.com">Contact us</a>
        </div>
      </div>
    </div>
  </div>

      </div>
    );
  }



  function renderAbout() {
  return (<div>

    <div class="1row 1align-items-md-stretch">
      <div class="col-md-12">
        <div id="about" class="h-100 p-5 text-white bg-success rounded-3">
          <h2>About Us</h2>
          <p>Greenhouse provides an open platform for business to transparently disclose Environment, Social and Governance (ESG) data to stakeholders.</p>
          <p>We believe that transparency forms the foundation of trust between a business adopting more sustainable practices and its stakeholders. Hence, Greenhouse allows business to disclose against a simple set of established ESG metrics in order to build that foundation, cutting through the ‘greenwash’ and PR campaigns that can sometimes distract from real data.
</p>
          <p>Greenhouse offers standard metrics that all business can use, along with a growing selection of additional metrics that are tailored to industry sectors.</p>
          <p>Basic business listings are free of charge.  Access to the website is also free without the need to register. Businesses can choose to pay for sector analytics and advanced listing features.</p>
          <p>Start building ‘ESG trust’ with your stakeholders today.</p>
          <a class="btn btn-outline-light" type="button" href="mailto:chris@greenhouseesg.com">Contact us</a>
        </div>
      </div>

      <div class="col-md-12">
        <div id="mission" class="h-100 p-5 bg-light border rounded-3">
          <h2>Mission</h2>
          <p className="lead">To achieve and exceed 2030 global sustainability goals by igniting a stakeholder movement behind sustainable business</p>
          <p>We believe that business will be the driving force behind achieving the 2030 UN Sustainability Goals.  That’s because business leaders understand the need to start adopting sustainable practices now in order to remain competitive tomorrow.</p>
          <p>Sustainability impacts almost every aspect of a business and requires management to address the needs of a broader set of stakeholders beyond the shareholders:</p>
          <ul>
          <li>Consumers – want to buy from brands that are making significant efforts to minimise their impact on the planet</li>
          <li>Employees – are choosing to work for businesses that have purpose and attention to equality, diversity and inclusion</li>
          <li>Supply chains – are being re-designed to build in supply resilience as well as integrity towards workers’ rights and impact on communities</li>
          </ul>
          <p>Greenhouse allows a business to disclose its ESG data in a consistent format on an open platform, available for all stakeholders to access.  This transparent disclosure signals a business’ commitment to sustainability and allows stakeholders to build trusted relationships with ‘better business’.</p>
          <p>Start building ‘ESG trust’ with your stakeholders today.</p>
          <a class="btn btn-outline-secondary" type="button" href="mailto:chris@greenhouseesg.com">Contact us</a>
        </div>
      </div>
    </div>

  </div>);

  }

  function renderListings() {
    return (
      <div className="notes">
	{renderLander()}
        {!isLoading && renderSectorsList(sectors)}
	{renderAbout()}
      </div>
    );
  }

  return (
    <div className="Home">
      { 
	renderListings()
      }
    </div>
  );
}
