import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import "./Home.css";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';

export default function Resources() {
  const history = useHistory();

  const [sectors, setSectors] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  async function onLoad() {
    //if (!isAuthenticated) {
    //  return;
    //}

    try {
        ReactGA.pageview(window.location.pathname);

document.title = "Resources";
document.getElementsByTagName("META")[2].content="Greenhouse transparently discloses sustainability goals and achievements for consumer brands.";

      const sectors = await loadSectors();
      setSectors(sectors);
    } catch (e) {
      onError(e);
    }

    setIsLoading(false);
  }

  onLoad();
}, [isAuthenticated]);


function loadSectors() {
  const sectors = [
	{sectorId: 'luxury_fashion', sectorName: 'Luxury Fashion'},
	{sectorId: 'fashion_highstreet', sectorName: 'Highstreet Fashion'},
	{sectorId: 'fashion_online', sectorName: 'Online Fashion'},
	{sectorId: 'sportswear_and_athleisure', sectorName: 'Sportswear & Athleisure'},
        {sectorId: 'watches_and_jewellery', sectorName: 'Watches & Jewellery'},
  ];
  return sectors

}


function renderSectorsList(sectors) {
  return (
    <>
    <div id="sectors" className="col-md-12 offset-0 card-body p-5 mb-1 bg-light rounded-3">
    <h2 class="pb-2 border-bottom">Sectors</h2>
    <div className="d-flex flex-wrap justify-content-start mb-3">
    {sectors.map(({sectorId, sectorName}) => (

      <div className="card m-2 bg-light h-100 overflow-hidden border-success rounded-5 shadow-lg" key={sectorId}  style={{width: "18rem"}}>
        <div className="card-body">
          <h5 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">{sectorName}</h5>
          <a href={`/${sectorId}`} className="card-link">View listings</a>
        </div>
      </div>

      ))}
    </div>
    </div>
    </>
  );
}

  function renderLander() {
    return (
      <div className="1lander">


  <div class="container-fluid px-5 py-5">
      <div class="col-md-12">
        <h1 class="display-5 fw-bold lh-1 mb-3">Resources</h1>
        <p class="lead">Here are some resourcs to help you understand the metrics we present on this site:</p>
      </div>
  </div>

      </div>
    );
  }



  function renderAbout() {
  return (<div>


    <div class="1row 1align-items-md-stretch">
      <div class="col-md-12">
        <div id="about" class="h-100 p-5 text-white bg-success rounded-3">
          <h2>SCOPE 1 vs SCOPE 2 vs SCOPE 3</h2>
          <p className="lead">Scope 1, 2 and 3 is a way of categorising the different kinds of greenhouse gas (GHG) emissions a company creates in its own operations, and in its wider value chain.</p>
          <p>Scope 1 — This one covers the Green House Gas (GHG) emissions that a company makes directly — for example while running its boilers and vehicles.</p>
          <p>Scope 2 — These are the emissions it makes indirectly – like when the electricity or energy it buys for heating and cooling buildings, is being produced on its behalf.</p>
          <p>Scope 3 — Now here’s where it gets tricky. In this category go all the emissions associated, not with the company itself, but that the organisation is indirectly responsible for, up and down its value chain. For example, from buying products from its suppliers, and from its products when customers use them. Emissions-wise, Scope 3 is nearly always the big one</p>
          <p className="text-sm">
Source: <a target="_blank" href="https://www2.deloitte.com/uk/en/focus/climate-change/zero-in-on-scope-1-2-and-3-emissions.html" className="text-white">https://www2.deloitte.com/uk/en/focus/climate-change/zero-in-on-scope-1-2-and-3-emissions.html</a></p>
        </div>
      </div>
    </div>



      <div class="col-md-12">

        <div id="mission" class="h-100 p-5 bg-light border rounded-3">

          <h2>CARBON NEUTRAL vs NET ZERO</h2>



          <p className="lead">What does being Carbon Neutral mean?</p>

          <p>Being Carbon Neutral means the amount of carbon (carbon dioxide, aka CO2) being emitted is equal to the amount of carbon being absorbed from the atmosphere thanks to carbon sinks such as forests, which absorb and store more carbon from the atmosphere than they emit. (European Parliament, 2021).</p>

          <p>For a company to be Carbon Neutral, typically a company first reduces their carbon (CO2) emissions as much as they can. Then they invest in carbon sinks (offsets) to balance out the remaining amount of carbon emitted by their operations. If all carbon emissions produced by the company are equal to the amount of emissions being reduced through their carbon offsets, the company is considered to be Carbon Neutral.</p>

          <p>It is important to note whether a company has committed to be Carbon Neutral for its own operations (see Scope 1 and 2 above) or its entire value chain (Scope 1, 2 & 3).  Scope 1 and 2 emissions typically account for only 5-25% of the company total</p>



          <p className="lead">What does Net Zero mean, and how is it different from Carbon Neutral?</p>

          <p>Net Zero is a similar concept to Carbon Neutral, however it goes beyond just carbon and is typically on a larger scale. Net Zero refers to when all greenhouse gases being emitted into the atmosphere are equivalent to the greenhouse gases being removed from the atmosphere on a global scale (ClimateSeed, 2021). In other words, net zero emissions will be achieved when human activity no longer causes global warming.</p>

          <p>Crucially, being Net Zero includes all greenhouse gases, not just carbon dioxide.</p>

          <p>Carbon dioxide (CO2) is the most well-known greenhouse gas, but other commonly referenced greenhouse gases include methane (CH4) and nitrous oxide (N20), and there are many more (David Suzuki Foundation, 2021). </p>

          <p>Some companies will be able to eliminate all the sources of greenhouse gases from their operations. This is called Absolute Zero and requires no offsets. However, the majority will not be able to achieve this target and will need to purchase offsets in order to account for greenhouse gases emitted. The combination of these two approaches is included in Net Zero.</p>

           <p>Again, it is important to note whether a company has committed to be Net Zero for its own operations (see Scope 1 and 2 above) or its entire value chain (Scope 1, 2 & 3).  Scope 1 and 2 emissions typically account for only 5-25% of the company total</p>

            <p>Source: <a target="_blank"href="https://ecometrica.com/carbon-neutral-net-zero/">https://ecometrica.com/carbon-neutral-net-zero/</a></p>

      </div>

    </div>



  </div>);

  }

  function renderListings() {
    return (
      <div className="notes">
	{renderLander()}
	{renderAbout()}
      </div>
    );
  }

  return (
    <div className="Home">
      { 
	renderListings()
      }
    </div>
  );
}
