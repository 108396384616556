import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
//import config from './config';


import ReactGA from 'react-ga';
const TRACKING_ID = "UA-180271949-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
        identityPoolId: 'us-east-1:46dc27c8-6699-4084-a1d1-e47d930ec9e2',

        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_knxiJjpc4',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '1ct0cnteruhsemj4638pptppsq',

        //storage: localStorage,

  },
  Storage: {
    region: 'us-east-1',
    bucket: 'lumiprotos',
    identityPoolId: 'us-east-1:3e8f3a90-4c50-4253-8718-74cedfa15959'
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
