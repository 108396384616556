import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import AWS from 'aws-sdk';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
//import ListingForm from "../components/ListingForm.js";

export default function NewListing() {
  const file = useRef(null);
  const history = useHistory();
  const [sectorName, setSectorName] = useState("luxury-fashion");
  const [companyName, setCompanyName] = useState(""); 
  const [companyId, setCompanyId] = useState("");
  const [brands, setBrands] = useState("");
  const [carbon, setCarbon] = useState({baselineYear: getYear()-5, year1: getYear() -3, year2: getYear()-2, year3: getYear()-1, targetYear: '2030'});
  const [dataSource, setDataSource] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return companyName.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }


        const handleChange = e => {
            const { name, value } = e.target;
            setCarbon(prevState => ({
                ...prevState,
                [name]: value
            }));
        };

function getYear() {
    return new Date().getFullYear();
}

async function handleSubmit(event) {
  event.preventDefault();

  setIsLoading(true);

  try {
    await createListing({ sectorName, companyId, companyName, brands, carbon});
    history.push(`/${sectorName}`);
  } catch (e) {
    onError(e);
    setIsLoading(false);
  }
}


async function createListing(data) {

    const credentials = await Auth.currentCredentials();


  const params = {
    TableName: 'dev-notes-Listings',
    Item: {
        sectorName: data.sectorName,
        companyId: data.companyId,
      companyName: data.companyName,
        carbon: data.carbon || null,
        brands: data.brands || null,
        dataSource: data.dataSource || null,
      createdAt: Date.now(), // Current Unix timestamp
    },
  };

var documentClient = new AWS.DynamoDB.DocumentClient({region: 'us-east-1', credentials: Auth.essentialCredentials(credentials)});

documentClient.put(params, function(err, data) {
  if (err) console.log(err);
  else console.log(data);
});


}



//function createListing(listing) {
//  return API.post("listings", "/listings", {
//    body: listing
//  });
//}


  return (
  <div className="col-md-8 offset-2">
      <Form onSubmit={handleSubmit}>

    <Form.Group controlId="sectorName" className="m-2">
        <Form.Label>Select Sector</Form.Label>
        <Form.Control
          as="select"
          name="sectorName"
          value={sectorName}
          onChange={(e) => setSectorName(e.target.value)}
        >
          <option value="luxury-fashion">Luxury Fashion</option>
          <option value="highstreet-fashion">Highstreet Fashion</option>
          <option value="online-fashion">Online Fashion</option>
          <option value="food-and-beverage">Food & Beverage</option>
          <option value="watches-and-jewellery">Watches & Jewellery</option>
          <option value="sportswear-and-athleisure">Sports & Athleisure</option>
        </Form.Control>
      </Form.Group>


        <Form.Group controlId="companyName" className="m-2">
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            value={companyName}
            type="text"
            onChange={(e) => {setCompanyName(e.target.value); setCompanyId(e.target.value.replace(/ /g,''))}}
          />
        </Form.Group>

        <Form.Group controlId="brands" className="m-2">
          <Form.Label>Brands</Form.Label>
          <Form.Control
            value={brands}
            type="text"
            onChange={(e) => setBrands(e.target.value)}
          />
        </Form.Group>

	<p>Carbon Emission Data</p>
	<table class="table" className="m-2">
        <thead>
          <tr>
      	    <th scope="col"></th>
            <th scope="col">Baseline</th>
      	    <th scope="col">Year -2</th>
      	    <th scope="col">Year -1</th>
            <th scope="col">Year 0</th>
            <th scope="col">Target</th>
    	  </tr>
  	</thead>

        <tr>
        <td>Years</td>
        <td>
        <Form.Group controlId="baselineYear">
          <Form.Control
            value={carbon.baselineYear}
            name="baselineYear"
            type="text"
            onChange={handleChange}
          />
        </Form.Group>
        </td>
        <td>
        <Form.Group controlId="year1">
          <Form.Control
            value={carbon.year1}
            type="text"
            name="year1"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="year2">
          <Form.Control
            value={carbon.year2}
            type="text"
            name="year2"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="year3">
          <Form.Control
            value={carbon.year3}
            name="year3"
            type="text"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="targetYear">
          <Form.Control
            value={carbon.targetYear}
            name="targetYear"
            type="text"
            onChange={handleChange}
          />
        </Form.Group>
        </td>
        </tr>

        <tr>
        <td>Scope 1 - Location Based</td>
        <td>
        <Form.Group controlId="s1BaselineYearValue">
          <Form.Control
            value={carbon.s1BaselineYearValue}
            type="text"
            name="s1BaselineYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s1Year1Value">
          <Form.Control
            value={carbon.s1Year1Value}
            type="text"
            name="s1Year1Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s1Year2Value">
          <Form.Control
            value={carbon.s1Year2Value}
            type="text"
            name="s1Year2Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s1Year3Value">
          <Form.Control
            value={carbon.s1Year3Value}
            type="text"
            name="s1Year3Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s1TargetYearValue">
          <Form.Control
            value={carbon.s1TargetYearValue}
            type="text"
            name="s1TargetYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td>
        </tr>

        <tr>
        <td>Scope 2 - Location Based</td>
        <td>
        <Form.Group controlId="s2BaselineYearValue">
          <Form.Control
            value={carbon.s2BaselineYearValue}
            type="text"
            name="s2BaselineYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2Year1Value">
          <Form.Control
            value={carbon.s2Year1Value}
            type="text"
            name="s2Year1Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2Year2Value">
          <Form.Control
            value={carbon.s2Year2Value}
            type="text"
            name="s2Year2Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2Year3Value">
          <Form.Control
            value={carbon.s2Year3Value}
            type="text"
            name="s2Year3Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2TargetYearValue">
          <Form.Control
            value={carbon.s2TargetYearValue}
            type="text"
            name="s2TargetYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td>
        </tr>


        <tr>
        <td>Scope 2 - Market Based</td>
        <td>
        <Form.Group controlId="s2MBaselineYearValue">
          <Form.Control
            value={carbon.s2MBaselineYearValue}
            type="text"
            name="s2MBaselineYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2MYear1Value">
          <Form.Control
            value={carbon.s2MYear1Value}
            type="text"
            name="s2MYear1Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2MYear2Value">
          <Form.Control
            value={carbon.s2MYear2Value}
            type="text"
            name="s2MYear2Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2MYear3Value">
          <Form.Control
            value={carbon.s2MYear3Value}
            type="text"
            name="s2MYear3Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s2MTargetYearValue">
          <Form.Control
            value={carbon.s2MTargetYearValue}
            type="text"
            name="s2MTargetYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td>
        </tr>


        <tr>
        <td>Scope 3 - Location Based</td>
        <td>
        <Form.Group controlId="s3BaselineYearValue">
          <Form.Control
            value={carbon.s3BaselineYearValue}
            type="text"
            name="s3BaselineYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s3Year1Value">
          <Form.Control
            value={carbon.s3Year1Value}
            type="text"
            name="s3Year1Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s3Year2Value">
          <Form.Control
            value={carbon.s3Year2Value}
            type="text"
            name="s3Year2Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s3Year3Value">
          <Form.Control
            value={carbon.s3Year3Value}
            type="text"
            name="s3Year3Value"
            onChange={handleChange}
          />
        </Form.Group>
        </td><td>
        <Form.Group controlId="s3TargetYearValue">
          <Form.Control
            value={carbon.s3TargetYearValue}
            type="text"
            name="s3TargetYearValue"
            onChange={handleChange}
          />
        </Form.Group>
        </td>
        </tr>


	</table>

        <Form.Group controlId="notes" className="m-2">
          <Form.Label>Notes (all scopes)</Form.Label>
          <Form.Control as="textarea"
            value={carbon.Notes}
	    name="notes"
      	    style={{ height: '100px', fontSize: 16 }}
            onChange={handleChange}
          />
        </Form.Group>

    <Form.Group controlId="scienceBasedTarget" className="m-2">
        <Form.Label>Select science based target</Form.Label>
        <Form.Control
          as="select"
	  name="scienceBasedTarget"
          value={carbon.scienceBasedTarget}
          onChange={handleChange}
        >
          <option value="No Target Set">No Target Set</option>
          <option value="1.5 degrees">1.5 degrees</option>
          <option value="Well below 2 degrees">Well below 2 degrees</option>
          <option value="Between 1.5 and 2 degrees">Between 1.5 and 2 degrees</option>
          <option value="2 degrees">2 degrees</option>
          <option value="Committed">Committed</option>
        </Form.Control>
      </Form.Group>

        <Form.Group controlId="carbonNeutralBy" className="m-2">
          <Form.Label>Carbon neutral by</Form.Label>
          <Form.Control
            value={carbon.carbonNeutralBy}
            type="text"
            name="carbonNeutralBy"
            onChange={handleChange}
          />
        </Form.Group>

    <Form.Group controlId="carbonNeutralFor" className="m-2">
        <Form.Label>Select carbon netural scope</Form.Label>
        <Form.Control
          as="select"
          name="carbonNeutralFor"
          value={carbon.carbonNeutralFor}
          onChange={handleChange}
        >
          <option value=""></option>
          <option value="for own operations">for own operations</option>
          <option value="for entire value chain">for entire value chain</option>
        </Form.Control>
      </Form.Group>

        <Form.Group controlId="netZeroBy" className="m-2">
          <Form.Label>Net zero by</Form.Label>
          <Form.Control
            value={carbon.netZeroBy}
            type="text"
            name="netZeroBy"
            onChange={handleChange}
          />
        </Form.Group>

    <Form.Group controlId="netZeroFor" className="m-2">
        <Form.Label>Select net zero scope</Form.Label>
        <Form.Control
          as="select"
          name="netZeroFor"
          value={carbon.netZeroFor}
          onChange={handleChange}
        >
          <option value=""></option>
          <option value="for own operations">for own operations</option>
          <option value="for entire value chain">for entire value chain</option>
        </Form.Control>
      </Form.Group>


        <Form.Group controlId="dataSource" className="m-2">
          <Form.Label>Data Source</Form.Label>
          <Form.Control
            value={carbon.dataSource}
	    name="dataSource"
            type="text"
            onChange={handleChange}
          />
        </Form.Group>

    <Form.Group controlId="netZeroFor" className="m-2">
        <Form.Label>Set transparency level</Form.Label>
        <Form.Control
          as="select"
          name="transparencyLevel"
          value={carbon.transparencyLevel}
          onChange={handleChange}
        >
          <option value=""></option>
          <option value="high">high</option>
          <option value="medium">medium</option>
          <option value="low">low</option>
        </Form.Control>
      </Form.Group>

        <div className="m-2 card">
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
	</div>
      </Form>
    </div>
  );
}
