import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useParams, useHistory } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import "./Home.css";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import AWS from 'aws-sdk';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import ReactGA from 'react-ga';

export default function Home() {

  const { sectorId } = useParams();
  const [listings, setListings] = useState([]);
  const history = useHistory();

  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);



useEffect(() => {
  async function onLoad() {
    //if (!isAuthenticated) {
      //return;
    //}
        ReactGA.pageview(window.location.pathname);

    try {

document.title = `Greenhouse ESG | ${sectorId.split('-').join(' ')}`;
document.getElementsByTagName("META")[1].content=`Discover the ESG sustainability achievements and targets for leading ${sectorId.split('-').join(' ')} brands. This includes Net Zero and Carbon Neutrality goals as well as actual Scope 1, 2 and 3 greenhouse gas (GHG) emissions.`;

      const companies = await loadListings(sectorId);
      if (companies.length==0) history.push('/404');
      const listings = await getBrands(companies);
      setListings(listings);
    } catch (e) {
      onError(e);
    }

    setIsLoading(false);
  }

  onLoad();
}, [isAuthenticated]);

function getBrands(companies) {

  var brandsList=[];
  var brands=[];
  var brand={};
  companies.map(co => {
    if (co.brands !== null) {
      brands=co.brands.split(", ");
      brands.map(br => {
	brand={};
	brand.brandName=br;
	brand.companyId=co.companyId;
	brand.companyName=co.companyName;
        brand.transparencyLevel=co.carbon.transparencyLevel;
        brandsList.push(brand);
      })
    }
  }) 
  brandsList.sort((a, b) => a.brandName.localeCompare(b.brandName));
  return brandsList;
}


async function loadListings(sectorId) {

    const credentials = await Auth.currentCredentials();

    var params = {
      TableName : 'dev-notes-Listings',
  	KeyConditionExpression: 'sectorName = :sectorId',
  	ExpressionAttributeValues: {
    	  ':sectorId': sectorId,
 	}
    };

    var documentClient = new AWS.DynamoDB.DocumentClient({region: 'us-east-1', credentials: Auth.essentialCredentials(credentials)});

    //documentClient.get(params, function(err, data) {
    //  if (err) console.log(err);
    //  else return data.Item;
    //});

    const response = await documentClient.query(params).promise();
    return response.Items;


}


//function loadListings(sectorId) {

//  try {
//    return API.get("listings", `/listings/sector/${sectorId}`);
//  } catch(e) {console.log(e)}

//}

function renderListingsList(listings) {
  return (
    <>

    <div className="col-md-12 offset-0 p-5 mb-4 bg-light rounded-3">
    <h2 className="pb-2 border-bottom">{sectorId.split('-').join(' ').toUpperCase()}</h2>
    <p className="fs-5 my-3">Discover the ESG sustainability achievements and targets for leading {sectorId.split('-').join(' ')} brands.  This includes Net Zero and Carbon Neutrality goals as well as actual Scope 1, 2 and 3 greenhouse gas (GHG) emissions.</p>
    <div className="d-flex flex-wrap justify-content-start mb-3">

      {isAuthenticated && <LinkContainer to="/new">
        <ListGroup.Item action className="py-3 text-nowrap text-truncate">
          <BsPencilSquare size={17} />
          <span className="ml-2 font-weight-bold"> Create a new listing</span>
        </ListGroup.Item>
      </LinkContainer>}

      {listings.map((brand) => (
      <div className={brand.transparencyLevel == 'high' ? 'border-2 border-success card m-2 bg-light vh-20 overflow-hidden rounded-5 shadow-lg' : brand.transparencyLevel == 'low' ? 'border-2 border-danger card m-2 bg-light vh-20 overflow-hidden rounded-5 shadow-lg' : 'border-2 border-warning card m-2 bg-light vh-20 overflow-hidden rounded-5 shadow-lg'} key={brand.brandName} style={{width: "18rem"}}>
        <div className="card-body">
          {brand.transparencyLevel && <p className="mt-2 text-capitalize"><small>Transparency: {brand.transparencyLevel}</small></p>}
          <h6 className="pt-3 mt-5 mb-4 display-6 h-1 fw-bold">{brand.brandName}</h6>
            <ul class="d-flex list-unstyled mt-auto">
              <li class="d-flex align-items-center me-3">
                <p>{brand.companyName}</p>
              </li>
              <li class="d-flex align-items-center">
                <p><a href={`/${sectorId}/${brand.companyId}`} className="card-link">View company listing</a></p>
              </li>
            </ul>        
         </div>
      </div>



      ))}
     </div>
     </div>

    </>
  );
}


  function renderListings() {
    return (
      <div className="notes">
        <div>{!isLoading ? renderListingsList(listings) : 'Loading...'}</div>
      </div>
    );
  }

  return (
    <div className="col-md-12 offset-0">
      {renderListings()}
    </div>
  );
}
