import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import { onError } from "../lib/errorLib";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../lib/awsLib";
import { LinkContainer } from "react-router-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { BsPencilSquare } from "react-icons/bs";
import { Bar } from 'react-chartjs-2';
import AWS from 'aws-sdk';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { useAppContext } from "../lib/contextLib";
import ReactGA from 'react-ga';
import  { Redirect } from 'react-router-dom'

export default function Listings() {
  const file = useRef(null);
  const { sectorName, companyId } = useParams();
  const history = useHistory();
  const [listing, setListing] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [brands, setBrands] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [chartData, setChartData] = useState("");
  const [chartWithFinancialsData, setChartWithFinancialsData] = useState("");
  const [financialsDataExists, setFinancialsDataExists] = useState(false);
  const [s1DataExists, setS1DataExists] = useState(true);
  const [s2ChartData, setS2ChartData] = useState("");
  const [s2DataExists, setS2DataExists] = useState(true);
  const [s3ChartData, setS3ChartData] = useState("");
  const [s3DataExists, setS3DataExists] = useState(true);
  const [chartFinancialsOptions, setChartFinancialsOptions] = useState("");
  const [chartOptions, setChartOptions] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const { isAuthenticated } = useAppContext();
  const [relativeEmissions, setRelativeEmissions] = useState(0);

  const fxRates= {
    GBP: 1,
    SEK: 13.01,
    CHF: 1.23,
    EUR: 1.21,
    USD: 1.34,
    CAD: 1.69,
    YEN: 155.14
  }


  useEffect(() => {


async function loadListing() {

    const credentials = await Auth.currentCredentials();

    var params = {
      TableName : 'dev-notes-Listings',
      Key: {
        sectorName: sectorName,
        companyId: companyId,
      }
    };

    var documentClient = new AWS.DynamoDB.DocumentClient({region: 'us-east-1', credentials: Auth.essentialCredentials(credentials)});

    const response = await documentClient.get(params).promise();
    return response.Item;

}


function checkExists (value1, value2, value3, value4) {
  var returnValue = false;
  //if (value1) {if (value1.length !==0) {returnValue=true}}
 // if (value2) {if (value2.length !==0) {returnValue=true}}
  //if (value3) {if (value3.length !==0) {returnValue=true}}
  //if (value4) {if (value4.length !==0) {returnValue=true}}


 if ((value1 !== null && value1 !=='') &&
   (value2 !== null && value2 !=='') &&
(value3 !== null && value3 !=='') &&
(value4 !== null && value4 !==''))
 { return true} else {return false}

  //return returnValue;

}





    async function onLoad() {
      try {
        ReactGA.pageview(window.location.pathname);

        const listing = await loadListing();
        if (listing==undefined) history.push('/404');
        const { companyName, brands, dataSource } = listing;

document.title = `Greenhouse ESG | ${listing.companyName}`;
document.getElementsByTagName("META")[1].content=`Discover the ESG sustainability achievements and targets for ${listing.companyName}. This includes ${listing.companyName}'s Net Zero and Carbon Neutrality goals as well as their actual Scope 1, 2 and 3 greenhouse gas (GHG) emissions – where data exists.`;



	var chartWithFinancialsData = {};
	var relativeEmissions=0;

        if (listing.financials && (
	  checkExists(listing.financials.revBaselineYearValue, listing.carbon.s3BaselineYearValue, listing.carbon.s1BaselineYearValue, listing.carbon.s2MBaselineYearValue) ||
	  checkExists(listing.financials.revYear1Value, listing.carbon.s3Year1Value, listing.carbon.s1Year1Value, listing.carbon.s2MYear1Value) ||
          checkExists(listing.financials.revYear2Value, listing.carbon.s3Year2Value, listing.carbon.s1Year2Value, listing.carbon.s2MYear2Value) ||
          checkExists(listing.financials.revYear3Value, listing.carbon.s3Year3Value, listing.carbon.s1Year3Value, listing.carbon.s2MYear3Value)  
	   || checkExists(listing.financials.revTargetYearValue, listing.carbon.s3TargetYearValue, listing.carbon.s1TargetYearValue, listing.carbon.s2MTargetYearValue) ) &&
	  listing.financials.reportingCurrency != null
          ) {
	setFinancialsDataExists(true);

        relativeEmissions=listing.financials ? (parseInt(listing.carbon.s3Year3Value)+parseInt(listing.carbon.s1Year3Value)+parseInt(listing.carbon.s2MYear3Value))*fxRates[listing.financials.reportingCurrency]*50/(parseInt(listing.financials.revYear3Value)*1000) : 0;

        chartWithFinancialsData = {
          labels: [`${listing.carbon.baselineYear} (Baseline)`, listing.carbon.year1, listing.carbon.year2, listing.carbon.year3, `${listing.carbon.targetYear} (Target)`],
           datasets: [
             {
               label: 'GHG per £50 Sales',
               data: [
                listing.financials ? (parseInt(listing.carbon.s3BaselineYearValue)+parseInt(listing.carbon.s1BaselineYearValue)+parseInt(listing.carbon.s2MBaselineYearValue))*fxRates[listing.financials.reportingCurrency]*50/(parseInt(listing.financials.revBaselineYearValue)*1000) : 0, 
		listing.financials ? (parseInt(listing.carbon.s3Year1Value)+parseInt(listing.carbon.s1Year1Value)+parseInt(listing.carbon.s2MYear1Value))*fxRates[listing.financials.reportingCurrency]*50/(parseInt(listing.financials.revYear1Value)*1000) : 0, 
		listing.financials ? (parseInt(listing.carbon.s3Year2Value)+parseInt(listing.carbon.s1Year2Value)+parseInt(listing.carbon.s2MYear2Value))*fxRates[listing.financials.reportingCurrency]*50/(parseInt(listing.financials.revYear2Value)*1000) : 0, 
		listing.financials ? (parseInt(listing.carbon.s3Year3Value)+parseInt(listing.carbon.s1Year3Value)+parseInt(listing.carbon.s2MYear3Value))*fxRates[listing.financials.reportingCurrency]*50/(parseInt(listing.financials.revYear3Value)*1000) : 0,
                listing.financials ? (parseInt(listing.carbon.s3TargetYearValue)+parseInt(listing.carbon.s1TargetYearValue)+parseInt(listing.carbon.s2MTargetYearValue))*fxRates[listing.financials.reportingCurrency]*50/(parseInt(listing.financials.revTargetYearValue)*1000) : 0,
		],
               backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
          ],
        }}




	if (listing.carbon.s1Year1Value == null &&
	  listing.carbon.s1Year2Value == null  &&
          listing.carbon.s1Year3Value == null &&
          listing.carbon.s1TargetYearValue == null) {setS1DataExists(false)}
        const chartData = {
          labels: [`${listing.carbon.baselineYear} (Baseline)`, listing.carbon.year1, listing.carbon.year2, listing.carbon.year3, `${listing.carbon.targetYear} (Target)`],
           datasets: [
             {
               label: 'Location Based',
      	       data: [listing.carbon.s1BaselineYearValue, listing.carbon.s1Year1Value, listing.carbon.s1Year2Value, listing.carbon.s1Year3Value, listing.carbon.s1TargetYearValue],
               backgroundColor: 'rgba(255, 99, 132, 0.2)',
      		borderColor: 'rgba(255, 99, 132, 1)',
      	        borderWidth: 1,
    	    },
  	  ],
	};


        if (listing.carbon.s2Year1Value == null &&
          listing.carbon.s2Year2Value == null  &&
          listing.carbon.s2Year3Value == null &&
          listing.carbon.s2TargetYearValue == null) {setS2DataExists(false)}
        const s2ChartData = {
          labels: [`${listing.carbon.baselineYear} (Baseline)`, listing.carbon.year1, listing.carbon.year2, listing.carbon.year3, `${listing.carbon.targetYear} (Target)`],
           datasets: [
             {
               label: 'Location Based',
               data: [listing.carbon.s2BaselineYearValue, listing.carbon.s2Year1Value, listing.carbon.s2Year2Value, listing.carbon.s2Year3Value, listing.carbon.s2TargetYearValue],
               backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
             {
               label: 'Market Based',
               data: [listing.carbon.s2MBaselineYearValue, listing.carbon.s2MYear1Value, listing.carbon.s2MYear2Value, listing.carbon.s2MYear3Value, listing.carbon.s2MTargetYearValue],
               backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
          ],
        };


        if (listing.carbon.s3Year1Value == null &&
          listing.carbon.s3Year2Value == null  &&
          listing.carbon.s3Year3Value == null &&
          listing.carbon.s3TargetYearValue == null) {setS3DataExists(false)}

        const s3ChartData = {
          labels: [`${listing.carbon.baselineYear} (Baseline)`, listing.carbon.year1, listing.carbon.year2, listing.carbon.year3, `${listing.carbon.targetYear} (Target)`],
           datasets: [
             {
               label: 'Location Based',
               data: [listing.carbon.s3BaselineYearValue, listing.carbon.s3Year1Value, listing.carbon.s3Year2Value, listing.carbon.s3Year3Value, listing.carbon.s3TargetYearValue],
               backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
          ],
        };

        const chartFinancialsOptions = {
          scales: {
            y: {
              title: {
                text: "KGs CO2e/£50",
                display: true,
              }
            }
          }
        }

	const chartOptions = {
	  scales: {
	    y: {
	      title: {
	        text: "Tonnes/CO2e",
	        display: true,
	      }
	    }
	  }
	}

        setCompanyName(companyName);
        setBrands(brands);
        setDataSource(dataSource);

        setChartWithFinancialsData(chartWithFinancialsData);
        setChartFinancialsOptions(chartFinancialsOptions);
        setRelativeEmissions(relativeEmissions);

        setChartData(chartData);
        setS2ChartData(s2ChartData);
        setS3ChartData(s3ChartData);
        setChartOptions(chartOptions);


        setListing(listing);
      } catch (e) {
        onError(e);
      }
      setIsLoading(false);
    }

    onLoad();
  }, [sectorName, companyId]);



return (

  <div className="col-md-12 offset-0 card-body">
      {isAuthenticated && <div className="card border-success m-2 card m-2 bg-light h-100 overflow-hidden rounded-5 shadow-lg">
      <LinkContainer className="" to={`/edit/${sectorName}/${companyId}`}>
        <ListGroup.Item action className="py-3 text-nowrap text-truncate">
          <BsPencilSquare size={17} />
          <span className="ml-2 font-weight-bold"> Edit this listing</span>
        </ListGroup.Item>
      </LinkContainer>
      </div>}

    {isLoading ? <div className="card-body">Loading...</div>  : (
    <div className="">

<nav aria-label="breadcrumb" className="mt-1">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/">Home</a></li>
    <li class="breadcrumb-item"><a href={`/${sectorName}`}>{sectorName}</a></li>
    <li class="breadcrumb-item active" aria-current="page">{companyName}</li>
  </ol>
</nav>

    <h2 class="pb-2 border-bottom mb-3 display-1">{listing.companyName}</h2>

    <div className="row">
    <p className="fs-5 my-3 col-md-5">Discover the ESG sustainability achievements and targets for {listing.companyName} which markets the brands listed below. This includes {listing.companyName}'s Net Zero and Carbon Neutrality goals as well as their actual Scope 1, 2 and 3 greenhouse gas (GHG) emissions – where data exists.</p>

    <div className="card border-success m-2 col-md-3 bg-light overflow-hidden rounded-5 shadow-lg">
      <div className="card-body p-md-5 text-center">
        {relativeEmissions == 0 ? <h1 className="display-41">Not available</h1> :<h1 className="display-1">{parseInt(relativeEmissions)}</h1>}
        <p>Kg Emissions per £50 of Sales</p>
      </div>
    </div>

    </div>


    <div className="card border-success m-2 col-md-8 bg-light overflow-hidden rounded-5 shadow-lg">
      <div className="card-body p-md-5">
        <h3 class="card-title">Brands</h3>
        <p>{listing.brands}</p>
      </div>
    </div>

      <div className="card border-success m-2 col-md-8 bg-light h-100 overflow-hidden rounded-5 shadow-lg">
          <div className="card-body p-md-5">
          <h3 class="card-title">Greenhouse Gas Emissions (GHG)  per £50 of Sales</h3>
          {financialsDataExists && <Bar className="" data={chartWithFinancialsData} options={chartFinancialsOptions}/>}
          {!financialsDataExists && <p class="mt-5 mb-4">Financials or emissions data not available</p>}

          </div>
      </div>

      <div className="card border-success m-2 col-md-8 bg-light h-100 overflow-hidden rounded-5 shadow-lg">
	  <div className="card-body p-md-5">
          <h3 class="card-title">Scope 1 - Greenhouse Gas (GHG) Emissions</h3>
          {s1DataExists && <Bar className="" data={chartData} options={chartOptions}/>}
	  {!s1DataExists && <p class="mt-5 mb-4">Company has not disclosed Scope 1 data</p>}
	  </div>
      </div>

      <div className="card border-success m-2 col-md-8 bg-light h-100 overflow-hidden rounded-5 shadow-lg">
          <div className="card-body p-md-5">
          <h3 class="card-title">Scope 2 - Greenhouse Gas (GHG) Emissions</h3>
          {s2DataExists && <Bar className="" data={s2ChartData} options={chartOptions}/>}
          {!s2DataExists && <p class="mt-5 mb-4">Company has not disclosed Scope 2 data</p>}
          </div>
      </div>

      <div className="card border-success m-2 col-md-8 bg-light h-100 overflow-hidden rounded-5 shadow-lg">
          <div className="card-body p-md-5">
          <h3 class="card-title">Scope 3 - Greenhouse Gas (GHG) Emissions</h3>
          {s3DataExists && <Bar className="" data={s3ChartData} options={chartOptions}/>}
          {!s3DataExists && <p class="mt-5 mb-4">Company has not disclosed Scope 3 data</p>}
          </div>
      </div>

          {listing.carbon.notes && <div className="card border-success m-2 bg-light col-md-8 h-100 overflow-hidden rounded-5 shadow-lg"><div class="p-md-5 card-body">
          <h3 class="card-title">Notes</h3>
          <p style={{whiteSpace: "pre-line"}}>{listing.carbon.notes}</p>
          </div></div>}

          {listing.carbon.scienceBasedTarget && <div className="card border-success m-2 col-md-8 bg-light h-100 overflow-hidden rounded-5 shadow-lg"><div className="p-md-5 card-body">
          <h3 class="card-title">Science Based Target</h3>
            <p>{listing.carbon.scienceBasedTarget}</p>
          </div></div>}

          {listing.carbon.carbonNeutralBy && <div className="card border-success m-2 col-md-8 bg-light h-100 overflow-hidden rounded-5 shadow-lg"><div className="p-md-5 card-body">
          <h3 class="card-title">Carbon Neutral By</h3>
            <p>{listing.carbon.carbonNeutralBy}{listing.carbon.carbonNeutralFor ? `, ${listing.carbon.carbonNeutralFor}` : ''} </p>
          </div></div>}

          {listing.carbon.netZeroBy && <div className="card border-success m-2 col-md-8 bg-light h-100 overflow-hidden rounded-5 shadow-lg"><div className="p-md-5 card-body">
          <h3 class="card-title">Net Zero By</h3>
            <p>{listing.carbon.netZeroBy}{listing.carbon.netZeroFor ? `, ${listing.carbon.netZeroFor}` : ''} </p>
          </div></div>}


          {listing.carbon.dataSource && <div className="card border-success m-2 col-md-8 bg-light h-100 overflow-hidden rounded-5 shadow-lg"><div className="p-md-5 card-body">
          <h3 class="card-title">Data Source</h3>
            <p><a href={listing.carbon.dataSource} target="_blank">{listing.carbon.dataSource}</a></p>
          </div></div>}


    </div>



    )}
  </div>
);


}
