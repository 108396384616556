import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Reset from "./containers/Reset";
import NewListing from "./containers/NewListing";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import EditListing from "./containers/EditListing";
import ViewListing from "./containers/ViewListing";
import SectorListings from "./containers/SectorListings";
import Resources from "./containers/Resources";


export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/resources">
        <Resources />
      </Route>
      <Route exact path="/404" status={404}>
        <NotFound />
      </Route>
<UnauthenticatedRoute exact path="/login">
  <Login />
</UnauthenticatedRoute>
<UnauthenticatedRoute exact path="/signup">
  <Signup />
</UnauthenticatedRoute>
<UnauthenticatedRoute exact path="/reset">
  <Reset />
</UnauthenticatedRoute>
<AuthenticatedRoute exact path="/new">
  <NewListing />
</AuthenticatedRoute>
<AuthenticatedRoute exact path="/edit/:sectorName/:companyId">
  <EditListing />
</AuthenticatedRoute>
<Route exact path="/:sectorName/:companyId">
  <ViewListing />
</Route>
<Route exact path="/:sectorId">
  <SectorListings />
</Route>

{/* Finally, catch all unmatched routes */}
<Route>
  <NotFound />
</Route>
    </Switch>
  );
}
