import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import "./App.css";
import Routes from "./Routes";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./lib/contextLib";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { onError } from "./lib/errorLib";


function App() {
const history = useHistory();
const [isAuthenticating, setIsAuthenticating] = useState(true);
const [isAuthenticated, userHasAuthenticated] = useState(false);

useEffect(() => {
  onLoad();
}, []);

async function onLoad() {
  try {

    await Auth.currentSession();
    userHasAuthenticated(true);
  }
  catch(e) {
    if (e !== 'No current user') {
onError(e);
    }
  }

  setIsAuthenticating(false);
}

async function handleLogout() {
  await Auth.signOut();

  userHasAuthenticated(false);

history.push("/login");

}

return (
  !isAuthenticating && (
    <div className="">
      <Navbar collapseOnSelect expand="md" className="navbar navbar-expand-lg navbar-dark bg-success mb-3">
      <div className="container-fluid">
        <LinkContainer to="/">
          <Navbar.Brand className="navbar-brand">
    <a class="navbar-brand" href="#">
      Greenhouse
    </a>        
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav><a className="text-white nav-link" href="/#sectors">Sectors</a></Nav>
          <Nav><a className="text-white nav-link" href="/#about">About</a></Nav>
	  <Nav><a className="text-white nav-link" href="/#mission">Mission</a></Nav>
          <Nav><a className="text-white nav-link" href="/resources">Resources</a></Nav>

          <Nav activeKey={window.location.pathname}>
            {isAuthenticated ? (
              <Nav.Link className="" onClick={handleLogout}>Logout</Nav.Link>
            ) : (
              <>
                <LinkContainer to="/login">
                  <Nav.Link className="">Login</Nav.Link>
                </LinkContainer>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </div>
      </Navbar>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
	<div className="container-fluid">
        <Routes />

        <footer class="pt-3 mt-4 text-muted border-top">
          <p> &copy; Greenhouse ESG 2021</p>
        </footer>

        </div>
      </AppContext.Provider>
    </div>
  )
);


}

export default App;
